// eslint-disable-next-line no-unused-vars
import { Dialog, showToast } from 'vant';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { get, post } from '@/utils/apiBase.js';
import lowerdev from '@/service/lowerdev';
import { env, domClientWidth, domPixelRatio, domTop, domHeight, domClientHeight } from "md-base-tools/env";
export default {
  name: 'orderList',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    [Dialog.name]: Dialog.Component
  },
  async setup() {
    const route = useRoute();
    const redirectUrl = decodeURIComponent(route.query.redirectUrl);
    const orderId = route.query.orderId ? route.query.orderId : null;
    const orderType = route.query.orderType ? route.query.orderType : null;
    const store = useStore();
    const {
      data
    } = await get('/apis/address/get_address_list', {
      json_type: '1',
      user_id: store.state.user.userId
    });
    return {
      list: ref(data),
      redirectUrl,
      orderId,
      orderType
    };
  },
  data() {
    return {
      address_id: '',
      dialogShow: false,
      env: env,
      isMobile: env.isMobile(),
      placeholder: '~@assets/images/orderlist/default_1x1@2x.png'
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  beforeMount() {
    if (sessionStorage.getItem('miniProgram') === 'weixin') {
      lowerdev.dynamicScript('https://res.wx.qq.com/open/js/jweixin-1.4.0.js');
    }
  },
  mounted() {
    console.log(this.redirectUrl);
    lowerdev.addbind(window, 'scroll', this.reloadScroll);
    // eslint-disable-next-line eqeqeq
    this.miniProgram = sessionStorage.getItem('miniProgram') == 'weixin';
  },
  watch: {},
  beforeUnmount() {
    lowerdev.removebind(window, 'scroll', this.reloadScroll);
  },
  methods: {
    async getList() {
      const {
        data
      } = await get('/apis/address/get_address_list', {
        json_type: '1',
        user_id: this.$store.state.user.userId
      });
      this.list = data;
    },
    add() {
      // this.$router.push('/address/add_address')
      this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(this.redirectUrl !== 'undefined' ? this.redirectUrl : location.href)}&orderId=${this.orderId}&orderType=${this.orderType}&add=true`);
    },
    edit(item) {
      this.$router.push(`/address/add_address/${item.id}?item=${JSON.stringify(item)}&redirectUrl=${encodeURIComponent(this.redirectUrl !== 'undefined' ? this.redirectUrl : location.href)}&orderId=${this.orderId}&orderType=${this.orderType}&add=true`);
      // sessionStorage.setItem('address', JSON.stringify(item))
    },
    // 返回
    selectBack(item) {
      // eslint-disable-next-line
      // graft.app({
      //     functionName: "closeController",
      //     webFun: () => {
      sessionStorage.setItem('address', JSON.stringify(item));
      history.go(-1);
      //     }
      // })

      // if (this.redirectUrl.includes('?')) {
      //   location.href = this.redirectUrl + `&item=${JSON.stringify(item)}`
      // } else {
      //   location.href = this.redirectUrl + `?item=${JSON.stringify(item)}`
      // }
    },
    async editOrderAddress(item) {
      if (this.orderId && this.orderType) {
        let updateAddress = '';
        const data = JSON.parse(JSON.stringify(item));
        let url = '';
        if (this.orderType === 'shop') {
          url = '/mall/order/address';
          updateAddress = await post(url, {
            order_id: this.orderId,
            address: JSON.stringify(data)
          });
        } else if (this.orderType === 'project') {
          url = '/apis/mdorder/order_modify_address';
          data.order_id = this.orderId;
          data.id = item.id;
          updateAddress = await post(url, data);
        }
        if (updateAddress) {
          showToast({
            type: 'text',
            message: updateAddress.message ? updateAddress.message : '操作成功',
            onClose: () => {
              // eslint-disable-next-line eqeqeq
              if (this.redirectUrl != 'undefined') {
                // this.dropBack()
                location.href = this.redirectUrl;
              } else {
                this.dropBack();
              }
            }
          });
        }
      } else {
        this.selectBack(item);
      }
    },
    close(item) {
      this.address_id = item.id;
      this.dialogShow = true;
    },
    deleteItem() {
      post('/apis/address/delete_address', {
        address_id: this.address_id,
        user_id: this.userId,
        json_type: '1'
      }).then(() => {
        this.dialogShow = false;
        this.getList();
      });
    },
    dropBack() {
      if (history.length > 1) {
        this.$router.back(-1);
      } else {
        lowerdev.openUrl();
      }
    },
    reloadScroll() {
      const localTop = domTop();
      if (this.loading) {
        return false;
      }
      if (localTop && domHeight() < localTop + domClientHeight() + 50) {
        this.loading = true;
        this.page_index = this.page_index + 1;
      }
    }
  }
};